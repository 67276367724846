function CreationFormQuestions({ activeStep, onFieldFocus, setCategory, setDescription, setStyle, setTitle, user, utilities, }) {
  function mapTitle(customClass, title) {
    return title.split(' ').map((word, i) => {
      return (
        <div className="word" key={ `word-${i}` }>
          { word.split('').map((char, i) => <div className={ `char ${customClass}` } key={ `char-${i}` }>{ char }</div>) }
        </div>
      )
    })
  }

  return (
    <div className="formSteps" data-step={ activeStep }>
      <div className="formSteps_slider">
        <div className={ `step step--first ${activeStep - 1 === 0 ? 'step--prev' : ''} ${activeStep === 0 ? 'step--active' : ''}` } data-step="0">
          <div className="step_title title-0">
            <h2 className="st1" data-split>
              { mapTitle('title-0', (user && user.username) ?  `Hello, ${user.username}` : 'Hello there.') }
            </h2>
          </div>
        </div>

        <div className={ `step ${activeStep - 1 === 1 ? 'step--prev' : ''} ${activeStep === 1 ? 'step--active' : ''} ${activeStep + 1 === 1 ? 'step--next' : ''}` } data-step="1">
          <div className="step_title title-1">
            <h2 className="st1" data-split>
              { mapTitle('title-1', 'Tell me the name of your website') }
            </h2>
          </div>

          <div className={ `step_content ${activeStep === 1 ? 'active' : ''}` }>
            <div className="formRow">
              <textarea data-focus data-autogrow rows="1" onChange={ (e) => { utilities.elements.setHeightToTrueSize(e.target); setTitle(e.target.value); } } onFocus={ onFieldFocus } placeholder="Type here"></textarea>
            </div>
          </div>
        </div>

        <div className={ `step ${activeStep - 1 === 2 ? 'step--prev' : ''} ${activeStep === 2 ? 'step--active' : ''} ${activeStep + 1 === 2 ? 'step--next' : ''}` } data-step="2">
          <div className="step_title title-2">
            <h2 className="st1" data-split>
              { mapTitle('title-2', 'Could you give me a short description') }
            </h2>
          </div>

          <div className={ `step_content ${activeStep === 2 ? 'active' : ''}` }>
            <div className="formRow">
              <textarea data-focus data-autogrow rows="1" onChange={ (e) => { utilities.elements.setHeightToTrueSize(e.target); setDescription(e.target.value); } } onFocus={ onFieldFocus } placeholder="Type here"></textarea>
            </div>
          </div>
        </div>

        <div className={ `step ${activeStep - 1 === 3 ? 'step--prev' : ''} ${activeStep === 3 ? 'step--active' : ''} ${activeStep + 1 === 3 ? 'step--next' : ''}` } data-step="3">
          <div className="step_title title-3">
            <h2 className="st1" data-split>
              { mapTitle('title-3', 'Select a category') }
            </h2>
          </div>

          <div className={ `step_content ${activeStep === 3 ? 'active' : ''}` }>
            <div className="radioButtons">
              <label htmlFor="option-3-1">
                <input type="radio" name="option-3" id="option-3-1" onChange={ (e) => setCategory('portfolio') } value="value 1" />
                <span>Portfolio</span>
              </label>

              <label className="locked" htmlFor="option-3-2">
                <input disabled type="radio" name="option-3" id="option-3-2" onChange={ (e) => setCategory('e-commerce') /* don't really change */ } value="value 2" />
                <span className="locked">E-Commerce</span>
              </label>
            </div>
          </div>
        </div>

        <div className={ `step ${activeStep - 1 === 4 ? 'step--prev' : ''} ${activeStep === 4 ? 'step--active' : ''} ${activeStep + 1 === 4 ? 'step--next' : ''}` } data-step="4">
          <div className="step_title title-4">
            <h2 className="st1" data-split>
              { mapTitle('title-4', 'Select a style') }
            </h2>
          </div>

          <div className={ `step_content ${activeStep === 4 ? 'active' : ''}` }>
            <div className="radioButtons radioButtons--style">
              <label htmlFor="style-minimal">
                <input type="radio" name="style" id="style-minimal" onChange={ (e) => setStyle('minimal') } value="minimal" />
                <span>Minimal</span>
              </label>

              <label className="locked" htmlFor="style-bold">
                <input disabled type="radio" name="style" id="style-bold" onChange={ (e) => setStyle('bold') } value="bold" />
                <span className="locked">Bold</span>
              </label>
            </div>
          </div>
        </div>

        <div className={ `step ${activeStep - 1 === 5 ? 'step--prev' : ''} ${activeStep === 5 ? 'step--active' : ''} ${activeStep + 1 === 5 ? 'step--next' : ''}` } data-step="5">
          <div className="step_title title-5">
            <h2 className="st1" data-split>
              { mapTitle('title-5', 'Import content') }
            </h2>
          </div>

          <div className={ `step_content ${activeStep === 5 ? 'active' : ''}` }>
            <div className="buttonsGroup">
              <button type="button" className="button locked">Photo</button>
              <button type="button" className="button locked">3D</button>
            </div>
          </div>
        </div>

        <div className={ `step ${activeStep - 1 === 6 ? 'step--prev' : ''} ${activeStep === 6 ? 'step--active' : ''} ${activeStep + 1 === 6 ? 'step--next' : ''}` } data-step="6">
          <div className="step_title title-6">
            <h2 className="st1" data-split>
              { mapTitle('title-6', 'Let’s build something beautiful') }
            </h2>
          </div>

          <div className={ `step_content ${activeStep === 6 ? 'active' : ''}` }></div>
        </div>

        <div className={ `step step--last ${activeStep - 1 === 7 ? 'step--prev' : ''} ${activeStep === 7 ? 'step--active' : ''} ${activeStep + 1 === 7 ? 'step--next' : ''}` } data-step="7">
          <div className={ `step_content ${activeStep === 7 ? 'active' : ''}` }>
            <div className="generationCircle" id="generation-circle">
              <div className="generationCircle_value" id="circle-progress-text">
                0%
              </div>

              <svg className="generationCircle_progress" version="1.1" viewBox="0 0 300 300" preserveAspectRatio="xMinYMin meet">
                <circle className="generationCircle_progress_bar" id="circle-progress-svg" r="149.5" cx="150" cy="150" fill="transparent" stroke="#2F2F2F" strokeDasharray="939" strokeDashoffset="939" strokeWidth="1px"></circle>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreationFormQuestions;
