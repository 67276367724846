const elements = require('./elements');
const routes = require('./routes');
const user = require('./user');

const utilities = {
  elements,
  routes,
  user,
}

export default utilities;
