function PrivacyPolicyPage() {
  return (
    <section className="main">
      <div className="page_content privacy-policy-page">

        <h1>
          Privacy policy
        </h1>

        <br />

        <p>
          <em>Effective Date: October 1st, 2023</em>
        </p>

        <p>
          This Privacy Policy outlines how Paralllel SAS (“Paralllel”, “we”, “us”) your personal information through our website – parallel.ai our mobile applications, our interactions with you through other modes of communication (such as SMS or instant messaging), and any other sites or services that link to this Privacy Policy (collectively, the “Services”).
        </p>

        <br />

        <br />

        <h2>
          Our General Approach
        </h2>

        <p>
          At the intersection of AI-driven web design and mobile technology, Paralllel stands as a beacon of innovation. We believe that our platform will redefine the way individuals and businesses establish their digital presence. Our ultimate aim is to harness AI to create intuitive, personalized, and efficient web design experiences.
        </p>

        <p>
          Our Privacy Policy is rooted in the understanding that to revolutionize web design, we need to deeply comprehend our users' preferences and interactions. As users engage with Paralllel, we gain invaluable insights that drive our platform's evolution to better serve you.
        </p>

        <p>
          Transparency is paramount, especially concerning user data. This Policy sheds light on our data practices, ensuring users are well-informed about our commitment to their privacy. Here are the key points:
        </p>

        <ul>
          <li>
            When you utilize Paralllel, we gather data, including your name, contact details, and IP address. This data facilitates the smooth operation of Paralllel, guides platform enhancements, ensures user safety, and complies with all relevant regulations.
          </li>

          <li>
            Your interactions and designs with Paralllel are treated with the utmost confidentiality. We pledge never to sell or share your data for advertising or promotional activities. Rigorous internal protocols are in place to safeguard user data access and usage.
          </li>

          <li>
            Adherence to our Acceptable Use Policy is mandatory. While using Paralllel, refrain from engaging in harmful, abusive, or illicit activities. Circumventing our security or attempting to decipher our algorithms, design templates, or source code is prohibited. Breaches detected by our systems may result in account suspension.
          </li>

          <li>
            Individuals below the age of 18 are not authorized to use Paralllel.
          </li>

          <li>
            While Paralllel strives for accuracy, AI may occasionally produce unconventional design choices. Always review and customize designs to ensure they align with your vision and objectives.
          </li>
        </ul>

        <br />

        <br />

        <h2>
          Information We Collect
        </h2>

        <p>
          Information you provide to us:
        </p>

        <ul>
          <li>
            <b>Contact and account information</b>, such as your first and last name, email address, and your phone number.
          </li>

          <li>
            <b>Content and metadata</b> of any messages or feedback you send via Paralllel or directly to our team.
          </li>

          <li>
            <b>Communications</b> that we exchange with you, including when you respond to our surveys or contact us with questions, feedback or otherwise.
          </li>

          <li>
            <b>Other data</b> not specifically listed here, which we will use as described in this Privacy Policy or as otherwise disclosed at the time of collection.
          </li>
        </ul>

        <p>
          Automatic data collection. We and our service providers may automatically log and combine information about you, your computer or mobile device, and your interaction over time with the Services, online resources and our communications, such as:
        </p>

        <ul>
          <li>
            Device data such as your computer's or mobile device's operating system type and version, manufacturer and model, browser type, screen resolution, device type (e.g., phone, tablet), IP address, unique identifiers, language settings and general location information such as city, state or geographic area.
          </li>

          <li>
            Online activity data like the pages or sections you accessed, duration on a page or section, the website you navigated from to access Paralllel, pathways between pages or sections, details about your activity on a page or section, access timestamps, and interactions with our communications.
          </li>

          <li>
            We use the following tools for automatic data collection:
          </li>

          <li>
            Cookies: Text files stored on a user's device by websites to uniquely recognize the user's browser or to retain information/settings in the browser, aiding in efficient navigation, remembering preferences, enabling features, and understanding user behavior.
          </li>

          <li>
            Local storage technologies, like HTML5, that provide cookie-equivalent functionality but can store larger amounts of data, including on your device outside of your browser in connection with specific applications.
          </li>

          <li>
            Web beacons, also known as pixel tags or clear GIFs, which are used to demonstrate that a webpage or email was accessed or opened, or that certain content was viewed or clicked.
          </li>
        </ul>

        <p>
          <b>Information we obtain from other sources.</b> If you choose to contact or otherwise interact with our Services over a third-party messaging platform such as Facebook Messenger or Instagram, we will collect an identifier of your profile on these platforms.
        </p>

        <p>
          <b>Sensitive data.</b> In your conversations with the Services, you may choose to provide sensitive information. This includes, but is not limited to, your religious views, sexual orientation, political views, health, racial or ethnic origin, philosophical beliefs, or trade union membership. By providing sensitive information, you consent to our use of it for the purposes set out in this Privacy Policy.
        </p>

        <br />

        <br />

        <h2>
          How We Use Your Information
        </h2>

        <p>
          We use personal information for the following purposes or as otherwise described at the time of collection:
        </p>

        <p>
          <b>Provide our Services.</b> We use personal information to operate, maintain, and provide you with our Services. In particular, we use personal information to perform our contractual obligations under our Terms of Service.
        </p>

        <p>
          <b>Communicate with you about our Services.</b> It is in our legitimate business interests to use personal information to respond to your requests, provide customer support, and communicate with you about our Services, including by sending announcements, surveys, reminders, updates, security alerts, and support and administrative messages.
        </p>

        <p>
          <b>Improve, monitor, personalize, and protect our Services.</b> It is in our legitimate business interests to improve and keep our Services safe for our users, which includes:
        </p>

        <ul>
          <li>
            Understanding your needs and interests, and personalizing your experience with the Services and our communications.
          </li>

          <li>
            Troubleshooting, testing, and research, and keeping the Services secure.
          </li>

          <li>
            Investigating and protecting against fraudulent, harmful, unauthorized, or illegal activity.
          </li>
        </ul>

        <p>
          <b>Research and development.</b> We may use personal information for research and development purposes where it is in our legitimate business interests, including to analyze and improve the Services and our business. As part of these activities, we may create or use aggregated, de-identified or other anonymized data from personal information we collect. We make personal information into anonymized data by removing information that makes the data personally identifiable to you. We may use this anonymized data and share it with third parties for our lawful business purposes, including to analyze and improve the Services and promote our business.
        </p>

        <p>
          <b>Compliance and protection.</b> We may use personal information to comply with legal obligations, and to defend us against legal claims or disputes, including to:
        </p>

        <ul>
          <li>
            Protect our, your, or others' rights, privacy, safety, or property (including by making and defending legal claims).
          </li>

          <li>
            Audit our internal processes for compliance with legal and contractual requirements and internal policies.
          </li>

          <li>
            Enforce the terms and conditions that govern the Services.
          </li>

          <li>
            Prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical, or illegal activity, including cyberattacks and identity theft.
          </li>

          <li>
            Comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities.
          </li>
        </ul>

        <br />

        <br />

        <h2>
          Sharing Your Information
        </h2>

        <p>
          We do not sell or share your personal information with third-parties for interest-based advertising purposes . We may disclose your personal information as described below:
        </p>

        <p>
          <b>Service providers.</b> We may rely on third-party service providers in furtherance of the purposes described above. In these cases, personal information may be accessed by these third-parties and processed or stored on our behalf. These service providers can include hosting services, cloud computing and storage services, maintenance services, security services, and customer support services. We also may share hashes of names and phone numbers and limited device data for advertising attribution and measurement services. This means that the recipient can recognize your name and phone number only if they already have that information from another source.
        </p>

        <p>
          <b>Business transferees.</b> Personal information may be transferred to another entity in the event of a bankruptcy, change of control, or shutdown of Paralllel. We may also transfer personal information in the course of a sale or merger of the business.
        </p>

        <p>
          <b>Authorities and others.</b> If we are legally obliged or otherwise believe it necessary to do so, personal information may be disclosed to regulatory agencies, law enforcement agencies, courts, and other government authorities, including for the compliance and protection purposes described above.
        </p>

        <br />

        <br />

        <h2>
          Privacy Rights and Choices
        </h2>

        <p>
          <b>Delete your account.</b> You may request that we delete your account by contacting us as provided in the « How to Contact Us » section below.
        </p>

        <p>
          <b>Opt out of SMS messaging.</b> If you wish to opt out of receiving text messages from Paralllel, reply with STOP to any mobile message sent from us. Please note that this may prevent us from sending one-time codes to your phone and may limit your experience. If you would like to allow these codes but disable all other messages, reply with PAUSE to any mobile message sent from us.
        </p>

        <p>
          <b>Online tracking technologies opt-out.</b> You can opt out of third-party cookies as described in our « Cookie Policy .»
        </p>

        <p>
          <b>Personal information requests.</b> We offer you choices that affect how we handle the personal information that we control. Depending on your location and the nature of your interactions with our Services, you may request the following in relation to your personal information:Personal information requests. We offer you choices that affect how we handle the personal information that we control. Depending on your location and the nature of your interactions with our Services, you may request the following in relation to your personal information:
        </p>

        <ul>
          <li>
            <b>Information</b> about how we have collected and used personal information. We have made this information available to you without having to request it by including it in this Privacy Policy.
          </li>

          <li>
            <b>Access</b> to a copy of the personal information that we have collected about you. Where applicable, we will provide the information in a portable, machine-readable, readily usable format.
          </li>

          <li>
            <b>Correction</b> of personal information that is inaccurate or out of date.
          </li>

          <li>
            <b>Deletion</b> of personal information that we no longer need to provide the Services or for other lawful purposes.
          </li>

          <li>
            <b>Withdrawal of consent</b>, where we have collected and processed your personal information with your consent. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
          </li>

          <li>
            <b>Additional rights</b>, such as to <b>object</b> to and request that we <b>restrict</b> our use of personal information.
          </li>
        </ul>

        <p>
          To make a request, please email us or write to us as provided in the « How to Contact Us » section below. We may ask for specific information from you to help us confirm your identity. Depending on where you reside, you may be entitled to empower an authorized agent to submit requests on your behalf. We will require authorized agents to confirm their identity and authority, in accordance with applicable laws. You are entitled to exercise the rights described above free from discrimination.
        </p>

        <p>
          <b>Limits on your privacy rights and choices.</b> In some instances, your choices may be limited, such as where fulfilling your request would impair the rights of others, our ability to provide a service you have requested, or our ability to comply with our legal obligations and enforce our legal rights. If you are not satisfied with how we address your request, you may submit a complaint by contacting us as provided in the « How to Contact Us » section below. Depending on where you reside, you may have the right to complain to a data protection regulator where you live or work, or where you feel a violation has occurred.
        </p>

        <p>
          <b>Do Not Track.</b> Some Internet browsers can be configured to send “Do Not Track” signals to the online services that you visit. We currently do not respond to “Do Not Track” or similar signals. To find out more about “Do Not Track,” please visit <a href="http://www.allaboutdnt.com" target="_blank">http://www.allaboutdnt.com</a>.
        </p>

        <br />

        <br />

        <h2>
          Security
        </h2>

        <p>
          Ensuring the integrity of your data is a major priority for us. Paralllel has technical measures in place to protect personal information against unauthorized access, corruption, loss, or misuse. This includes internal measures that limit access to personal information to a dedicated set of specialist employees working on improving the safety or quality of our Services. Where possible and appropriate, we also de-identify sensitive data like conversational logs to preserve user privacy.
        </p>

        <p>
          However, these measures are not a guarantee of absolute security, and you acknowledge and accept that your use of our Services is ultimately at your own risk.
        </p>

        <p>
          Also, please note that you are responsible for managing access to any accounts that you maintain with Paralllel. Failure to limit access to your devices or browser might enable third-parties to have unauthorized access to your personal information.
        </p>

        <br />

        <br />

        <h2>
          Third-Party Websites and Content
        </h2>

        <p>
          Please be advised that you may encounter links or content through our Services that are provided by third-parties that ParalllelI does own or otherwise control. You acknowledge that any data you provide to these third-parties shall be governed solely by the terms of service, privacy policies, and other terms applicable to those third-parties.
        </p>

        <br />

        <br />

        <h2>
          Children
        </h2>

        <p>
          Our Services are not intended for minors under the age of 18. We do not knowingly collect or solicit personal information from minors under the age of 18. If you are a minor under the age of 18, please do not attempt to use our Services, register an account with us, or send any personal information to us.
        </p>

        <p>
          If you believe that a minor under the age of 18 has provided personal information to us or is otherwise using our Services, please let us know immediately at support@paralllel.ai and we will seek to delete this information and revoke access as quickly as possible.
        </p>

        <br />

        <br />

        <h2>
          Retention
        </h2>

        <p>
          Where required under applicable laws, we retain personal information only for as long as is necessary to fulfill the purposes for which it was collected and processed, in accordance with our retention policies, and in accordance with applicable laws and regulatory obligations or until you withdraw your consent (where applicable).
        </p>

        <p>
          To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of personal information, the purposes for which we use personal information and whether we can achieve those purposes through other means, and the applicable legal and regulatory requirements.
        </p>

        <br />

        <br />

        <h2>
          International Data Transfers
        </h2>

        <p>
          You will provide personal information directly to our Services in the United States and in Europe. We may also transfer personal information to our affiliates, partners, and service providers in the United States and other jurisdictions. Please note that such jurisdictions may not provide the same protections as the data protection laws in your home country.
        </p>

        <br />

        <br />

        <h2>
          Changes To This Policy
        </h2>

        <p>
          We are continuing to iterate on our Services, and this Privacy Policy will need to be updated. Please be aware that we reserve the right to modify this Privacy Policy at any time.
        </p>

        <p>
          When we do so, and unless required by law to provide an alternative notice, we will post an updated version on this page. If you choose to continue your use of our Services after such a revision, you consent to be governed by the amended Privacy Policy.
        </p>

        <br />

        <br />

        <h2>
          How to Contact Us
        </h2>

        <p>
          <b>Responsible entity.</b> Paralllel is the entity responsible for the processing of personal information under this Privacy Policy (as a controller, where provided under applicable law).
        </p>

        <p>
          Please reach out to the Paralllel team at support@paralllel.ai if you have any questions or concerns pertaining to this Privacy Policy.
        </p>

        <br />

        <br />

        <h2>
          Cookie Policy
        </h2>

        <p>
          <em>Last updated: September 19, 2023</em>
        </p>

        <p>
          This Cookie Policy explains how Paralllel SAS (“Paralllel”, “we”, or “us”) uses cookies and similar technologies when you visit our Services as defined in our Privacy Policy. Here, we explain what these technologies are and why we use them, as well as your rights to control our use of them.
        </p>

        <br />

        <h3>
          1. What are cookies and similar technologies?
        </h3>

        <p>
          <b>Cookies.</b> A cookie is a text file containing a string of characters that is sent to your device when you visit a website. When you visit the website again, the cookie allows that website to recognize your browser. Cookies may store user preferences and other information. Cookies set by the website owner are called “first party cookies.” Cookies set by parties other than the website owner are called “third party cookies.” Third party cookies enable third party features or functionality to be provided on or through a website (e.g., interactive content, and analytics). The parties that set these third party cookies can recognize your device both when it visits the website in question and also when it visits certain other websites.
        </p>

        <p>
          <b>Local storage.</b> Local storage technologies refer to the methods that websites and applications use to store data locally on your device. The most commonly used local storage technology is called “local Storage” and is part of the HTML5 standard. This technology allows websites or applications to store data that persists even after the user closes their browser or application, or restarts their device.
        </p>

        <p>
          <b>Session storage.</b> Session storage is a feature of your web browser or device that allows a website or application to temporarily store data on your device while you are actively using the website or application. This data is deleted as soon as you close your browser or application, or navigate away from the website. Websites and applications may use session storage to improve your experience and ensure that certain information is readily available during your browsing session.
        </p>

        <p>
          <b>Other tracking technologies.</b> Websites and apps use a variety of other tracking technologies too, including web beacons (also known as tracking pixels), third-party tracking scripts, and analytics tools. These technologies provide data on how visitors use websites. We may also use web beacons and “clear GIFs” in communications with you to allow us to count how many people read them and to verify any clicks through to links within an email. If you do not wish for the web beacon to be downloaded onto your device, you should select to receive emails from us in plain text rather than HTML.
        </p>

        <p>
          This Cookie Policy refers to all these technologies collectively as “cookies.”
        </p>

        <br />

        <h3>
          2. What type of information do cookies collect?
        </h3>

        <p>
          We and our service providers may automatically log and combine information about you, your computer or mobile device, and your interaction over time with the Services, online resources and our communications, such as:
        </p>

        <ul>
          <li>
            Device data such as your computer's or mobile device's operating system type and version, manufacturer and model, browser type, screen resolution, device type (e.g., phone, tablet), IP address, unique identifiers, language settings and general location information such as city, state or geographic area.
          </li>

          <li>
            Online activity data such as pages or screens you viewed, how long you spent on a page or screen, the website you visited before browsing to the Services, navigation paths between pages or screens, information about your activity on a page or screen, access times, duration of access and whether you have opened or otherwise engage with our communications.
          </li>
        </ul>

        <p>
          We use both persistent cookies and session cookies. Persistent cookies stay on your device for a set period of time or until you delete them, while session cookies are deleted once you close your web browser.
        </p>

        <br />

        <h3>
          3. Why do we use cookies?
        </h3>

        <p>
          <b>We only use essential cookies.</b> These cookies are strictly necessary to provide you with our Services, by, for example, providing page navigation and access to secure areas of the Services.
        </p>

        <br />

        <h3>
          4. How can you control cookies?
        </h3>

        <p>
          You can set your browser to block cookies, but then some parts of the Services will not work given that we only use cookies that are strictly necessary to provide you with our Services. To do this, follow the instructions in your browser settings. Many browsers accept cookies by default until you change your settings. For more information about cookies, including how to see what cookies have been set on your device and how to manage and delete them, visit www.allaboutcookies.org. Use the following links to learn more about how to control cookies and online tracking through your browser: Firefox; Chrome; Microsoft Edge; Safari.
        </p>

        <p>
          Note that because these opt-out mechanisms are specific to the device or browser on which they are exercised, you will need to opt out on each browser and device that you use.
        </p>

        <p>
          <b>Do Not Track.</b> Some Internet browsers can be configured to send “Do Not Track” signals to the online services that you visit. We currently do not respond to “Do Not Track” or similar signals. To find out more about “Do Not Track,” please visit http://www.allaboutdnt.com.
        </p>

        <br />

        <h3>
          5. How often will we update this Cookie Policy?
        </h3>

        <p>
          We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. We will notify you of any material changes to this Cookie Policy prior to the changes becoming effective by posting the changes on this page and providing a more prominent notice with on-site or email notifications. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.
        </p>

        <p>
          The date at the top of this Cookie Policy indicates when it was last updated.
        </p>

        <br />

        <h3>
          6. Where can you get further information?
        </h3>

        <p>
          Please reach out to the Paralllel team at support@paralllel.ai if you have any questions or concerns pertaining to this Cookie Policy.
        </p>
      </div>
    </section>
  )
}

export default PrivacyPolicyPage;
