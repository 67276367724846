import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';

import Assistant from '../common/Assistant';
import TemporaryAlert from '../common/TemporaryAlert';

function ShowExperimentPage() {
  const navigate = useNavigate();

  const { experimentId } = useParams();

  const [experiment, setExperiment] = useState(null);

  useEffect(getExperiment, [ experimentId, navigate, ]);

  function getExperiment() {
    fetch(`${process.env.REACT_APP_API_URL}/v1/experiments/${experimentId}`, { headers: { 'Content-Type': 'application/json', }, method: 'GET', })
      .then((res) => { if(!res.ok) throw new Error(); return res.json() })
      .then((data) => setExperiment(data.experiment))
      .catch(() => navigate('/'));
  }
  
  if (!experiment) return null;

  return (
    <div className="ShowExperimentPage">
      <TemporaryAlert />

      <Assistant options={ ['edit', 'share', ] } title="Preview Mode" />

      <iframe className={ `page-option-preview full-site` }
              height="100%"
              id={ experimentId }
              key={ experimentId }
              src={ `${process.env.REACT_APP_API_URL}/v1/templates/view?${experiment.pageOptionRows.map((options) => options.find((option) => option.selected) || options[0]).map((option, i) => `&${i}=${option._id}`).join('')}` }
              width="100%"
      />
    </div>
  );
}

export default ShowExperimentPage;
