import { useEffect, useState, } from 'react';
import { useNavigate, } from 'react-router-dom';

function SignInPage({ utilities, }) {
  const navigate = useNavigate();

  useEffect( utilities.user.requireUnauthenticated, []);
  
	const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');

  function signIn() {
    fetch(`${process.env.REACT_APP_API_URL}/v1/users/authenticate`, { body: JSON.stringify({ username, password, }), headers: { 'Content-Type': 'application/json', }, method: 'POST', })
      .then((res) => { if (!res.ok) throw new Error('Invalid credentials'); return res.json(); })
      .then((data) => {
        utilities.user.handleSignInResponse(data);
        navigate(`/experiments/create`);
      })
      .catch((err) => localStorage.setItem('message-for-user', err));  
  }

  return (
    <section className="main">
      <div className="page_content">
        <h3 className="st2">Sign In</h3>
        
        <div className="form">
          <div className="formRow">
            <input type="text" onChange={ (e) => setUsername(e.target.value) } placeholder="Username" value={ username }/>
          </div>

          <div className="formRow">
            <input type="password" onChange={ (e) => setPassword(e.target.value) } placeholder="Password" value={ password } />
          </div>
        </div>
      </div>

      <div className="page_actions">
        <button className="button button--filled button--sized" disabled={ !username || !password } onClick={ signIn }>
          <span>Sign In</span>
        </button>
      </div>
    </section>
  )
}

export default SignInPage;
