function handleSignInResponse(data) {
  if (!!data._id) {
    localStorage.setItem("userId", data._id);
    localStorage.setItem("email", data.email);
    localStorage.setItem("phoneNumber", data.phoneNumber);
    localStorage.setItem("username", data.username);
  }
}

function isSignedIn() {
  return !!localStorage.getItem('userId');
}

function requireAuthenticated() {
  if (!isSignedIn()) {
    localStorage.setItem('message-for-user', 'You must be signed in to access that page.');
    window.location.assign('/');
  }
}

function requireUnauthenticated() {
  if (isSignedIn()) {
    localStorage.setItem('message-for-user', 'You are already signed in.');
    window.location.assign('/');
  }
}

function signOut() {
  localStorage.removeItem('email');
  localStorage.removeItem('phoneNumber');
  localStorage.removeItem('userId');
  localStorage.removeItem('username');
  window.location.reload();
}

module.exports = {
  handleSignInResponse,
  isSignedIn,
  requireAuthenticated,
  requireUnauthenticated,
  signOut,
};
