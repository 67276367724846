function TermsOfUsePage() {
  return (
    <section className="main">
      <div className="page_content terms-of-use-page">
        <h1>
          Terms of Service
        </h1>

        <br />

        <em>
          Effective Date: October 1st, 2023
        </em>

        <br />

        <p>
          Hello and welcome to Parallel’s Terms of Use! We're genuinely glad you're here and appreciate you choosing our platform.
        </p>

        <br />

        <p>
          Below, we've laid out some key guidelines for everyone using our website and services. It’s our way of ensuring things run smoothly, and everyone's on the same page. Remember, Parallel offers many different services, so not every rule might touch on what you're specifically diving into.
        </p>

        <br />

        <p>
          Reading legal stuff isn't always a walk in the park, but we've done our best to keep it straightforward. If you feel there's room for improvement or have any questions, don't hesitate to let us know.
        </p>

        <br />

        <p>
          These Terms of Use (“Terms”) govern your use of Paralllel SAS (“Paralllel”, “we”, “us”) products and services, including our website and generative AI builder (“Services”).
        </p>

        <p>
          These Terms incorporate by reference our Privacy Policy, as well as any other written policies and documentation that we may provide from time to time. You agree to use our Services in compliance with these Terms.
        </p>

        <p>
          Your use of our Services in any manner means that you agree to the Terms. Unfortunately, if you do not agree with these Terms, you may not access or use our Services.
        </p>

        <br />

        <br />

        <h2>
          Our Services
        </h2>

        <p>
          Paralllel is crafting a mobile-first, no-code website builder powered by AI.
        </p>


        <p>
          Given the early stage of this technology, we also acknowledge that there are areas that we will need to continually improve on to make sure that our Services can reach their full potential. As part of that, you recognize that:
        </p>

        <ul>
          <li>
            We will use the content of your generated websites to improve our Services. We will use the content of your generated websites as well as other metadata to train our models, improve our Services, and create new Services. Our uses of this data are detailed in our Privacy Policy.
          </li>

          <li>
            You must use our Services in accordance with our Acceptable Use policy. Your access to our Services is conditioned on your agreement to use the Services in accordance with these Terms generally and our Acceptable Use Policy (as detailed below in “Acceptable Use”).
          </li>

          <li>
            The information you receive from our Services may not be accurate. You recognize that any information you receive from our Services may not be accurate or otherwise trustworthy. Do not rely on this information without fact-checking on your own first or consulting with a professional. Getting factuality right remains an active area of research for the entire field of generative AI, and it remains a significant priority for the development of Paralllel services going forward. Please review the “No Warranties” section below.
          </li>
        </ul>

        <br />

        <br />

        <h2>
          Registering for Our Services
        </h2>

        <p>
          You register for our Services by providing us with your name and phone number. In doing so, you agree to:
        </p>

        <ul>
          <li>
            Provide us with complete and accurate registration information. You may not use a phone number that you do not control, and you may not attempt to impersonate another person in registration.
          </li>

          <li>
            If you are registering for our Services on behalf of an organization, you warrant that you are authorized to agree to these Terms on their behalf.
          </li>

          <li>
            Be responsible for the security of your account. You accept that you are solely responsible for all activities that take place through your account, and that failure to limit access to your devices or browser may permit unauthorized use by third parties.
          </li>
        </ul>

        <p>
          Finally, our Services are not intended for minors under the age of 18. If you are a minor under the age of 18, please do not register for our Services or send any Personal Information to us. If you have reason to believe that a minor under the age of 18 is using our Services, please let us know immediately at support@paralllel.ai, and we will seek to revoke access and delete any associated information as quickly as possible.
        </p>

        <br />

        <br />

        <h2>
          Acceptable Use
        </h2>

        <p>
          You agree that you will use our Services in accordance with the following rules, as well as any other written policies we may provide from time to time:
        </p>

        <ul>
          <li>
            <em>Illegal Use</em>: You must use our Services in compliance with the law. If your use of our Services are limited by the laws applicable to you, you are not authorized to use our Services for that purpose.
          </li>

          <li>
            <em>Harmful Uses</em>: You may not use or attempt to use our Services to generate harmful content. Such content includes material that may lead to serious harm to yourself or others, content relating to unethical behavior, or content that may spread misinformation. This policy also bars attempts to use the Services to create content to be fraudulently represented as human-generated.
          </li>

          <li>
            <em>Abusive Content</em>: You may not use or attempt to use our Services to generate hateful or discriminatory content, sexually explicit content, descriptions of graphic violence, or other types of shocking material.
          </li>

          <li>
            <em>Infringing Rights</em>: You may not use or attempt to use our Services to infringe or violate the rights of others, including violations of the privacy of others. This includes queries to surface the personal information of individuals, including phone numbers, addresses, and other similar sensitive data.
          </li>

          <li>
            <em>Security</em>: You may not attempt to engage with our Services in an effort to disable, disrupt, or otherwise subvert the security of our Services. This includes attempts to bypass or disable any content moderation and safety measures implemented within our Services.
          </li>

          <li>
            <em>Reverse Engineering</em>: You may not use or attempt to use our Services to reverse engineer, decompile, or otherwise attempt to obtain the underlying models, algorithms, or source code of the Services. You may not engage in this or any other activities with regard to our Services to build products that may be competitive with Paralllel.
          </li>

          <li>
            <em>Scraping</em>: You may not scrape or attempt to “crawl” or “spider” any page, data, or portion of our Services, either via manual or automated means.
          </li>
        </ul>

        <br />

        <br />

        <h2>
          Content
        </h2>

        <p>
          You will input text and other forms of media (“Content”) during your use of our Services. You agree that you shall not input any Content that is not owned by you unless you have prior written consent from the relevant rightsholder of that Content.
        </p>

        <p>
          While you will own any Content, you agree to provide a royalty-free, perpetual, irrevocable, and worldwide license to Paralllel to the Content for the following limited purposes:
        </p>

        <ul>
          <li>
            <em>Operating the Services</em>: You grant Paralllel a license to use, reproduce, modify, or otherwise act on the Content in order to enable the provision and maintenance of the Services. This includes uses to comply with applicable laws, enable the provision of our Services across channels and platforms, ensure user safety and security, and enforce our policies.
          </li>

          <li>
            <em>Improving the Services</em>: You grant Paralllel a license to use the Content to improve our Services and develop new Services. This license shall permit uses including but not limited to analysis of the content of conversations to understand opportunities for new features, the use of Content for model training and fine-tuning, and evaluating the performance of our existing Services.
          </li>
        </ul>

        <p>
          This license should be read alongside our Privacy Policy, which details how we use the Content and manage it internally. Please review it carefully.
        </p>

        <br />

        <br />

        <h2>
          Intellectual Property
        </h2>

        <ul>
          <li>
            <em>Paralllel Intellectual Property</em>: These Terms do not provide you with any right, title, or interest in our Services, our trademarks, or other intellectual property of Paralllel.
          </li>

          <li>
            <em>Feedback</em>: We welcome any and all feedback, ideas for improvement, product proposals, and other suggestions. However, if you provide this to us, you recognize that we may use this without restriction and without any further notice or compensation to you.
          </li>

          <li>
            <em>Copyright Notices</em>: If you believe that your copyright has been infringed by something on our Services, you can send us a DMCA-compliant copyright notice by sending an email to support@paralllel.ai On receipt of a compliant notice, we shall work to promptly remove or disable material identified as infringing. Please include the following information in your notice:

            <ul>
              <li>
                A description of the work or works you claim have been infringed;
              </li>

              <li>
                A description of the content on our Services you claim to be infringing;
              </li>

              <li>
                Your contact information, including mailing address, telephone number, and email address;
              </li>

              <li>
                Your statement confirming a good faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law;
              </li>

              <li>
                Your statement confirming that the information you’ve provided is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;
              </li>

              <li>
                Your signature
              </li>
            </ul>
          </li>
        </ul>

        <br />

        <br />

        <h2>
          Limitation of Liability
        </h2>

        <p>
          UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN CONTRACT, TORT, OR OTHERWISE) SHALL PARALLLEL BE LIABLE TO YOU OR ANY THIRD PARTY FOR (A) ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFITS, LOST SALES OR BUSINESS, LOST DATA, OR (B) FOR ANY DIRECT DAMAGES, COSTS, LOSSES OR LIABILITIES IN EXCESS ONE HUNDRED ($10) U.S. DOLLARS. THE PROVISIONS OF THIS SECTION ALLOCATE THE RISKS UNDER THESE TERMS BETWEEN THE PARTIES, AND THE PARTIES HAVE RELIED ON THESE LIMITATIONS IN DETERMINING WHETHER TO ENTER INTO THESE TERMS.
        </p>

        <br />

        <br />

        <h2>
          Third-party services and Websites
        </h2>


        <p>
          The Services may provide links or other access to services, sites, technology, and resources that are provided or otherwise made available by third parties (the “Third-Party Services”). Your access and use of the Third-Party Services may also be subject to additional terms and conditions, privacy policies, or other agreements with such third party, and you may be required to authenticate to or create separate accounts to use Third-Party Services on the websites or via the technology platforms of their respective providers. For more information about the implications of activating Third-Party Services and our use, storage and disclosure of information related to you and your use of such Third-Party Services within the Service, please see our Privacy Policy.
        </p>

        <p>
          Paralllel has no control over and is not responsible for such Third-Party Services, including for the accuracy, availability, reliability, or completeness of information shared by or available through Third-Party Services, or on the privacy practices of Third-Party Services. We encourage you to review the privacy policies of the third parties providing Third-Party Services prior to using such services. You, and not Paralllel, will be responsible for any and all costs and charges associated with your use of any Third-Party Services. Paralllel enables these Third-Party Services merely as a convenience, and the integration or inclusion of such Third-Party Services does not imply an endorsement or recommendation. Any dealings you have with third parties while using the Service are between you and the third party. Paralllel will not be responsible or liable, directly, or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any Third-Party Services.
        </p>

        <br />

        <br />

        <h2>
          Terminating Your Account
        </h2>

        <p>
          Paralllel is free to terminate or suspend access to your use of our Services at any time and for any reason at our discretion. Reasons for termination may include our determination that you have violated these Terms, where we determine it is necessary to ensure user safety, for legal compliance reasons, or where we believe it necessary to protect the rights or property of Paralllel.
        </p>

        <p>
          You may also request a deletion of our account by contacting support@paralllel.ai Please be advised that for security and privacy reasons, we may request information to verify your entity in order to process these requests.
        </p>

        <p>
          You agree that provisions of these Terms pertaining to indemnification, limitations of liability, intellectual property, dispute and arbitration, and any other terms that - by their nature - should survive termination of the Terms, shall survive.
        </p>

        <br />

        <br />

        <h2>
          Changes to the Services
        </h2>

        <p>
          Artificial intelligence is a fast-moving field, and Paralllel is continually working to make our Services better for our users. This means that our Services can and will change over time. Paralllel may augment, modify, discontinue, or suspend any part of our Services at any time. We will work to provide advance notice to you where appropriate, but you acknowledge that this may not always be possible.
        </p>

        <br />

        <br />

        <h2>
          Changes to These Terms
        </h2>


        <p>
          Paralllel reserves the right to amend these Terms anytime for any reason. We will provide notice of these changes by updating this page with the revised Terms and updating the effective date. No other amendments to the Terms shall be effective unless in writing and signed by you and a representative of Paralllel.
        </p>

        <p>
          The use of any of our Services after such an amendment to the Terms shall be considered an acceptance of all changes to the Terms. If you do not agree with the revised Terms, you must immediately cease any use of our Services.
        </p>

        <br />

        <br />

        <h2>
          Miscellaneous Terms
        </h2>


        <ul>
          <li>
            <em>Violations</em>: Paralllel reserves the sole discretion to determine whether or not a user is in violation of any of the provisions of these Terms.
          </li>

          <li>
            <em>Indemnification</em>: To the fullest extent allowed by applicable law, you agree to indemnify and hold Paralllel, its affiliates, officers, agents, employees, and partners harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to any third party claims relating to (a) your use of our Services (including any actions taken by a third party using your account), and (b) your violation of these Terms.
          </li>

          <li>
            <em>Assignment</em>: You may not assign or delegate any rights or obligations under these Terms, including in connection with a change of control. Any purported assignment and delegation shall be null and void. We may assign these Terms in connection with a merger, acquisition or sale of all or substantially all of our assets, or to any affiliate or as part of a corporate reorganization.
          </li>

          <li>
            <em>Choice of Law</em>: Your use of the Paralllel website and services shall be governed in all respects by the laws of the state of France, without regard to choice of law provisions, and not by the 1980 U.N. Convention on contracts for the international sale of goods. You agree that jurisdiction over and venue in any legal proceeding directly or indirectly arising out of or relating to these sites (including but not limited to the purchase of Paralllel products and services) shall be in the courts located in France. Any cause of action or claim you may have with respect to these sites (including but not limited to the purchase of Paralllel products and services) must be commenced within one (1) year after the claim or cause of action arises. Paralllel’s failure to insist upon or enforce strict performance of any provision of these terms and conditions shall not be construed as a waiver of any provision or right. Neither the course of conduct between the parties nor trade practice shall act to modify any of these terms and conditions. Paralllel may assign its rights and duties under this Agreement to any party at any time without notice to you. Paralllel makes no representation that materials on the Website are appropriate or available for use in other locations and access to them from territories where their content is illegal or prohibited. Those who choose to access this Website from locations outside France do so on their own initiative and are responsible for compliance with applicable local laws. You may not use or export the materials in violation of U.S. and EU/European export laws and regulations. The internal substantive laws of France shall govern any claim relating to the materials.
          </li>

          <li>
            <em>Class Action Waiver</em>: To the fullest extent permitted by law, you and Paralllel agree that all claims against the other can only be brought in an individual capacity, and not as a plaintiff or class member in any purported class, consolidated, or other representative proceedings. We agree that arbitrators may not conduct any class, consolidated, or representative proceeding, and are limited to providing relief warranted by an individual party’s claim.
          </li>

          <li>
            <em>No Joint Venture or Beneficiaries</em>: You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of Paralllel, and you do not have any authority of any kind to bind us in any respect whatsoever. There are also no third-party beneficiaries intended under these Terms.
          </li>

          <li>
            <em>Waiver</em>: The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further rights hereunder.
          </li>

          <li>
            <em>Notices</em>: Notices under these Terms shall be provided to you under the information provided to Paralllel when you registered your account. Notices to Paralllel may be provided by sending a message to support@paralllel.ai or by mail at Paralllel, 54/56 Avenue Hoche, 75008 Paris, France. Notice shall be considered effective as of the date of receipt.
          </li>

          <li>
            <em>Severability</em>: If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full force and effect and enforceable.
          </li>

          <li>
            <em>Entire Agreement</em>: You agree that these Terms are the complete and exclusive statement of the mutual understanding between you and us and that it supersedes and cancels all previous written and oral agreements, communications, and other understandings relating to the subject matter of these Terms.
          </li>

          <li>
            <em>Translation</em>: This Agreement was originally written in English. We may translate this Agreement into other languages. In the event of a conflict between a translated version and the English version, the English version will control except where prohibited by applicable law.
          </li>
        </ul>
      </div>
    </section>
  )
}

export default TermsOfUsePage;
