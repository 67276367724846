import { useEffect, useState, } from 'react';

function TemporaryAlert() {
  const [managedAlertText, setManagedAlertText] = useState('');

  useEffect(setUpPolling, []);

  function clearAlert() {
    const existingAlert = document.getElementById('temporary-alert');
    if (existingAlert) existingAlert.classList.add('ended');
    setTimeout(() => setManagedAlertText(''), 100);
  }

  function pollForAlert() {
    const message = localStorage.getItem('message-for-user');
    if (message && message !== managedAlertText) setAlert(message);
  }

  function setAlert(text) {
    // clear to prevent duplicates
    localStorage.removeItem('message-for-user');

    // remove old alerts
    if (!!managedAlertText) clearAlert();

    // set up new alert
    setTimeout(() => setManagedAlertText(text), 150);

    // clear new alert in 5 seconds
    setTimeout(clearAlert, 5000);
  }

  function setUpPolling() {
    const interval = setInterval(pollForAlert, 250);
    return () => clearInterval(interval);
  }

  return (
    <span id="temporary-alerts-wrapper">
      { managedAlertText &&
        <div id="temporary-alert">
          { managedAlertText }
        </div>
      }
    </span>
  )
}

export default TemporaryAlert;
