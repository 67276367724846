import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';

function Assistant({ options, title, }) {
  const navigate = useNavigate();

  const { experimentId } = useParams();

  const [experiment, setExperiment] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [showPopOutMenu, setShowPopOutMenu] = useState(false);
  const [viewerIsCreator, setViewerIsCreator] = useState(false);

  useEffect(getExperiment, [ experimentId, navigate, ]);
  useEffect(checkExperimentOwnership, [experiment, ]);

  function checkExperimentOwnership() {
    if (!experiment) return;
    setViewerIsCreator(localStorage.getItem('userId') === experiment.creatorId)
  }

  function copyShareableLink() {
    setAssistantVisibility(false);
    localStorage.setItem('message-for-user', 'Link copied to clipboard.');
    navigator.clipboard.writeText(window.location.origin + window.location.pathname);
  }

  function getExperiment() {
    fetch(`${process.env.REACT_APP_API_URL}/v1/experiments/${experimentId}`, { headers: { 'Content-Type': 'application/json', }, method: 'GET', })
      .then((res) => { if(!res.ok) throw new Error(); return res.json() })
      .then((data) => setExperiment(data.experiment))
      // .catch(() => navigate('/'));
  }

  function goToEdit() {
    setAssistantVisibility(false);
    setTimeout(() => navigate(`/experiments/${experimentId}/edit`), 750);
  }

  function goToPreview() {
    setAssistantVisibility(false);
    setTimeout(() => navigate(`/experiments/${experimentId}`), 750);
  }

  function setAssistantVisibility(show) {
    setIsInitialLoad(false);
    setShowPopOutMenu(show);
  }
  
  if (!experiment) return null;

  return (
    <div className={ `Assistant ${viewerIsCreator ? '' : 'hidden'} ${isInitialLoad ? 'initial-load' : showPopOutMenu ? 'center' : 'side'}` }>
      <div id="pop-out-background-blur"></div>

      <div id="pop-out-tab">
        <div id="assistant-label-row">
          <span className="pop-out-caret" onClick={ () => setAssistantVisibility(true) }>
            <img src="https://paralllel-webpage-assets.s3.eu-west-3.amazonaws.com/icons/chevron-left.svg" />
          </span>

          <h3 id="pop-out-title">
            { title }
          </h3>

          <button id="pop-out-close-button" onClick={ () => setAssistantVisibility(false) }>
            <img src="https://paralllel-webpage-assets.s3.eu-west-3.amazonaws.com/icons/close.svg" />
          </button>
        </div>
      

        <div id="preview-page-pop-out-content" className={ showPopOutMenu ? 'shown' : 'hidden' }>
          { options.includes('edit') &&
            <span className="content-row" onClick={ goToEdit }>
              <span className="text">
                Edit
              </span>
              
              <span className="icon">
                <img src="https://paralllel-webpage-assets.s3.eu-west-3.amazonaws.com/icons/edit.svg" />
              </span>
            </span>
          }

          { options.includes('preview') &&
            <span className="content-row" onClick={ goToPreview }>
              <span className="text">
                Preview
              </span>

              <span className="icon">
                <img src="https://paralllel-webpage-assets.s3.eu-west-3.amazonaws.com/icons/preview.svg" />
              </span>
            </span>
          }
          
          { options.includes('share') &&
            <span className="content-row" onClick={ copyShareableLink }>
              <span className="text">
                Share
              </span>
              
              <span className="icon">
                <img src="https://paralllel-webpage-assets.s3.eu-west-3.amazonaws.com/icons/share.svg" />
              </span>
            </span>
          }
        </div>
      </div>
    </div>
  );
}

export default Assistant;
