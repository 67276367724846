import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';

import Assistant from '../common/Assistant';
import OptionsRow from './OptionsRow';

function EditExperimentPage({ utilities, }) {
  const navigate = useNavigate();
  const { experimentId } = useParams();
  
  const [experiment, setExperiment] = useState({ });

  useEffect( utilities.user.requireAuthenticated, [ ]);
  useEffect(getExperiment, [ experimentId, navigate, ]);

  function getExperiment() {
    fetch(`${process.env.REACT_APP_API_URL}/v1/experiments/${experimentId}`, { headers: { 'Content-Type': 'application/json', }, method: 'GET', })
      .then((res) => { if(!res.ok) throw new Error(); return res.json() })
      .then((data) => {
        if (data.experiment.creatorId !== localStorage.getItem('userId')) navigate(`/experiments/${data.experiment._id}`);
        setExperiment(data.experiment);
      })
      .catch(() => navigate('/'));
  }
  
  return (
    <span>
      <div className="EditExperimentPage">

        <Assistant options={ ['preview', 'share', ] } title="Selection Mode" />

        <div className={ `visible-options-window ${experiment.pageOptionRows === undefined ? 'loading' : ''}` }>
          { experiment && experiment.pageOptionRows && experiment.pageOptionRows.map((pageOptionRow, columnIndex) => {
              return <OptionsRow columnIndex={ columnIndex } key={ pageOptionRow[0]._id } pageOptionRow={ pageOptionRow } />;
            })
          }
        </div>
      </div>
    </span>
  );
}

export default EditExperimentPage;
