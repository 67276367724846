import { useEffect, useState, } from 'react';

function OptionsRow({ columnIndex, pageOptionRow, }) {
  const [selectedOptionId, setSelectedOptionId] = useState('');
  const [rowId, setRowId] = useState(Math.random().toString(36).substring(2) + Date.now().toString(36));

  useEffect(() => { findSelectedElement() }, [ ]);

  async function findSelectedElement() {
    const middleElement = document.elementFromPoint(window.innerWidth / 2, window.innerHeight / 2);
    if (middleElement.getAttribute('option-row-key') === rowId && middleElement.getAttribute('is-selected') !== 'true') {
      setSelectedOptionId(middleElement.id);
      await updateSelection(middleElement.id);
    }

    setTimeout(findSelectedElement, 1000);
  }

  async function updateSelection(optionId) {
    await fetch(`${process.env.REACT_APP_API_URL}/v1/templates/${optionId}/select`, { body: JSON.stringify({ }), headers: { 'Content-Type': 'application/json', }, method: 'PUT', });
  }
  
  return (
    <span className="OptionsRow">
      { pageOptionRow.map((pageOption, rowIndex) => {
          return (
            <span className={ `page-option-scale-container ${pageOption.pageSectionTemplate.sectionType}` } key={ pageOption._id }>
              <iframe className={ `page-option-preview ${pageOption.pageSectionTemplate.sectionType}` }
                      height="100%"
                      id={ pageOption._id }
                      is-selected={ `${selectedOptionId === pageOption._id}` }
                      key={ pageOption._id }
                      loading={ [1, 2, ].includes(columnIndex) && [1, 2, ].includes(rowIndex) ? "eager" : "lazy" }
                      option-row-key={ rowId }
                      src={ `${process.env.REACT_APP_API_URL}/v1/templates/${pageOption._id}` }
                      title={ `${pageOption._id}` }
                      width="100%"
              />
          </span>
          )
        })
      }
    </span>
  )
}

export default OptionsRow;
