import { useNavigate, } from "react-router-dom";

function Welcome({ utilities, }) {
  const navigate = useNavigate();
  const userIsSignedIn = !!localStorage.getItem('userId');

  return (
    <section className="main">
      <div className="page_content page_content--middle">
        <h2 className="st1">Hello, and welcome to Paralllel</h2>
      </div>

      <video className="hidden-preloader" autoPlay muted playsInline>
        <source src="https://paralllel-webpage-assets.s3.eu-west-3.amazonaws.com/videos/intro-fullscreen.mp4" type="video/mp4" />
      </video>

      <video className="hidden-preloader" autoPlay muted playsInline>
        <source src="https://paralllel-webpage-assets.s3.eu-west-3.amazonaws.com/videos/loop-fullscreen.mp4" type="video/mp4" />
      </video>

      { userIsSignedIn ?
          <div className="page_actions">
            
            <button className="button button--filled button--sized" onClick={ () => navigate('/experiments/create', { state: { activeStep: 1, }, }) }>
              <span className="flex-row centered">
                <span className="flex-column centered">
                  <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.5 6.906h4.156a.75.75 0 0 1 0 1.5H8.5v4.157a.75.75 0 1 1-1.5 0V8.406H2.844a.75.75 0 0 1 0-1.5H7V2.75a.75.75 0 1 1 1.5 0v4.156Z" />
                  </svg>
                </span>

                <span style={{ margin: '0 2px', }}></span>

                <span className="flex-column centered">
                  <span>Create</span>
                </span>
              </span>
            </button>
          </div>

        :

          <div className="page_actions">
            
            {/* <button className="button button--filled button--sized" onClick={ () => navigate('/sign-up') }>
              <span>Sign Up</span>
            </button>

            <br /> */}
            
            <button className="button button--sized" onClick={ () => navigate('/sign-in') }>
              <span>Sign In</span>
            </button>
          </div>
      }
    </section>
  );
}

export default Welcome;
