import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import utilities from '../../utilities';

import CreateExperimentPage from '../createExperiment/_CreateExperimentPage';
import EditExperimentPage from '../editExperiment/_EditExperimentPage';
import Header from '../common/Header';
import PrivacyPolicyPage from '../privacyPolicy/_PrivacyPolicyPage';
import ShowExperimentPage from '../showExperiment/_ShowExperimentPage';
import SignInPage from '../signIn/_SignInPage';
import RegistrationPage from '../registration/_RegistrationPage';
import TermsOfUsePage from '../termsOfUse/_TermsOfUsePage';
import WelcomePage from '../welcome/_WelcomePage';

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Header utilities={ utilities } />

        <Routes>
          <Route exact path={ utilities.routes.home.path } element={ <span><WelcomePage utilities={ utilities } /></span> } name="home" ></Route>
          <Route exact path={ utilities.routes.experiments_create.path } element={ <span><CreateExperimentPage utilities={ utilities } /></span> } name="create"></Route>
          <Route exact path={ utilities.routes.experiments_show.path } element={ <ShowExperimentPage /> } name="show"></Route>
          <Route exact path={ utilities.routes.experiments_edit.path } element={ <span><EditExperimentPage utilities={ utilities } /></span> } name="edit"></Route>
          <Route exact path={ utilities.routes.legal_privacyPolicy.path } element={ <span><PrivacyPolicyPage /></span> } name="privacy-policy"></Route>
          <Route exact path={ utilities.routes.legal_termsOfUse.path } element={ <span><TermsOfUsePage /></span> } name="terms-of-use"></Route>
          <Route exact path={ utilities.routes.users_signIn.path } element={ <span><SignInPage utilities={ utilities } /></span> } name="sign-in"></Route>
          <Route exact path={ utilities.routes.users_signUp.path } element={ <span><RegistrationPage utilities={ utilities } /></span> } name="sign-up"></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
