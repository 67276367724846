import { useEffect, useState, } from 'react';
import { useNavigate, } from 'react-router-dom';

function RegistrationPage({ utilities, }) {
  const navigate = useNavigate();

  useEffect( utilities.user.requireUnauthenticated, []);
  
	const [inviteCode, setInviteCode] = useState('');
  const [password, setPassword] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [username, setUsername] = useState('');

  function createUser() {
    fetch(`${process.env.REACT_APP_API_URL}/v1/users`, { body: JSON.stringify({ inviteCode, password, phoneNumber, username, }), headers: { 'Content-Type': 'application/json', }, method: 'POST', })
      .then((res) => res.json())
      .then((data) => {
        utilities.user.handleSignInResponse(data);
        navigate(`/experiments/create`);
      })
      .catch(() => localStorage.setItem('message-for-user', 'Invalid username or invite code.'));
  }

  return (
    <section className="main">
      <div className="page_content p-signup">
        <h3 className="st2">Sign Up</h3>

        <p className="sp1">You will use your phone number and password to log into your Paralllel account and save your work.</p>
        
        <div className="form">
          <div className="formRow">
            <input type="text" onChange={ (e) => setInviteCode(e.target.value) } placeholder="Invite Code" value={ inviteCode } />
          </div>

          <div className="formRow">
            <input type="text" onChange={ (e) => setPhoneNumber(e.target.value) } placeholder="Phone Number" value={ phoneNumber } />
          </div>

          <div className="formRow">
            <input type="text" onChange={ (e) => setUsername(e.target.value) } placeholder="Username" value={ username }/>
          </div>

          <div className="formRow">
            <input type="password" onChange={ (e) => setPassword(e.target.value) } placeholder="Password" value={ password } />
          </div>
        </div>

        <p className="sp3">You agree to receive automated text messages from us, even if your mobile number is listed on any do-not-call registry. You also agree to our Terms of Use and acknowledge our Privacy Policy. Message frequency varies. <br />Message and data rates may apply. Text STOP to cancel.</p>
      </div>

      <div className="page_actions">
        <button className="button button--filled button--sized" disabled={ !username || !password } onClick={ createUser }>
          <span>Sign Up</span>
        </button>
      </div>
    </section>
  )
}

export default RegistrationPage;
