import { useEffect, } from 'react';

function VideoBackground({ selectedStyle, }) {
  useEffect(addStyle, [ selectedStyle, ]);

  function addStyle() {
    const videoContainer = document.getElementById('video-background-container');

    if (!!videoContainer) {
      ['bold', 'default', 'minimal'].forEach((cls) => videoContainer.classList.remove(cls));
      videoContainer.classList.add(selectedStyle || 'default');
    }
  }

  function playLoop() {
    const containerElem = document.getElementById('video-background-container');
    const loopVideo = document.getElementById('orb-loop');

    loopVideo.play();
    containerElem.classList.add('intro-ended');
  }

  return (
    <div className="videoBackground" id="video-background-container">
      <video id="orb-intro" onEnded={ playLoop } autoPlay muted playsInline>
        <source src="https://paralllel-webpage-assets.s3.eu-west-3.amazonaws.com/videos/intro-fullscreen.mp4" type="video/mp4" />
      </video>

      <video id="orb-loop" loop muted playsInline>
        <source src="https://paralllel-webpage-assets.s3.eu-west-3.amazonaws.com/videos/loop-fullscreen.mp4" type="video/mp4" />
      </video>
    </div>
  )
}

export default VideoBackground;
