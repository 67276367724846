import { useEffect, useState, } from 'react';
import { useLocation, useNavigate, } from "react-router-dom";

import TemporaryAlert from './TemporaryAlert';

function Header({ pageName, utilities, }) {
	const location = useLocation();
  const navigate = useNavigate();

	const [menuOpen, setMenuOpen] = useState(false);
  const [experimentLimit, setExperimentLimit] = useState(5);
  const [experiments, setExperiments] = useState([]);
	const [selectedSubMenu, setSelectedSubMenu] = useState('');
  const [showHeader, setShowHeader] = useState(false);
	// this batch of user fields should be broken out into separate component
	const [userEmail, setUserEmail] = useState('');
	const [userLanguage, setUserLanguage] = useState('english');
	const [userPassword, setUserPassword] = useState('xxxxxxxx');
	const [userPhoneNumber, setUserPhoneNumber] = useState('');
	const [userReceiveEmails, setUserReceiveEmails] = useState(true);
	const [userReceiveTexts, setUserReceiveTexts] = useState(true);
	const [userUsername, setUserUsername] = useState('');

  useEffect(() => { fetchExperiments(); }, [experimentLimit, ]);
  useEffect(() => { handleRouteChange(); }, [location, ]);

	function closeSubmenu() {
		setSelectedSubMenu('');
	}

  function fillUserInfo() {
    setUserEmail(localStorage.getItem('email') || '');
    setUserPhoneNumber(localStorage.getItem('phoneNumber') || '');
    setUserUsername(localStorage.getItem('username') || '');
  }

  function goToCreate() {
    navigate('/experiments/create', { state: { activeStep: 1, }, });
    setMenuOpen(false);
  }

  async function fetchExperiments() {
    const userId = localStorage.getItem('userId');

    if (!!userId) {
      fetch(`${process.env.REACT_APP_API_URL}/v1/experiments?recordLimit=${experimentLimit}`, { headers: { 'Authorization': userId, 'Content-Type': 'application/json', }, method: 'GET', })
      .then((res) => res.json())
      .then((data) => setExperiments(data.experiments));
    } else {
      setTimeout(fetchExperiments, 2000);
    }
  }

  function handleRouteChange() {
    const routerInfo = Object.values(utilities.routes).find((route) => route.regex.test(location.pathname)) || {};
    setShowHeader(routerInfo.includeHeader || false);
  }

	function toggleMenu() {
    // if menu is not already open, fill in user info
    if(!menuOpen) fillUserInfo();

		setMenuOpen(!menuOpen);
		setSelectedSubMenu('');
	}

  function updateUser() {
    fetch(`${process.env.REACT_APP_API_URL}/v1/users/${localStorage.getItem('userId')}`, { body: JSON.stringify({ email: userEmail, phoneNumber: userPhoneNumber, username: userUsername, }), headers: { 'Content-Type': 'application/json', }, method: 'PUT', })
      .then((res) => res.json())
      .then((data) => {
        utilities.user.handleSignInResponse(data);
        localStorage.setItem('message-for-user', 'Profile updated.');
      });
  }

  if (!showHeader) return null;

	return (
		<span>
			<div className={ `navbar ${menuOpen ? 'shorten' : 'full-height'}` }>
        <span className="main-paralllel-portion">
          <a className="navbar_logo" onClick={ () => navigate('/') }></a>

          <button className={ `navbar_menuButton ${menuOpen ? 'opened' : ''}` } onClick={ toggleMenu } type="button">
            <span></span>
            <span></span>
            <span></span>
          </button>
        </span>
			</div>

      <TemporaryAlert />

			<div className={ `menu ${menuOpen ? 'visible' : ''}` }>
				<nav className="menu_mainNav">
					<ul>
						<li><a href="#" onClick={ () => setSelectedSubMenu('projects') }>Projects</a></li>
						<li><a href="#" onClick={ () => setSelectedSubMenu('profile') }>Profile</a></li>
						<li><a href="#" onClick={ () => setSelectedSubMenu('notifications') }>Notifications</a></li>
						<li><a href="#" onClick={ () => setSelectedSubMenu('language') }>Language</a></li>
					</ul>
				</nav>

				<div className="submenu_actions">
          <button className="button button--filled button--sized" onClick={ goToCreate }>
            <span className="flex-row centered">
              <span className="flex-column centered">
                <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M8.5 6.906h4.156a.75.75 0 0 1 0 1.5H8.5v4.157a.75.75 0 1 1-1.5 0V8.406H2.844a.75.75 0 0 1 0-1.5H7V2.75a.75.75 0 1 1 1.5 0v4.156Z" />
                </svg>
              </span>

              <span style={{ margin: '0 2px', }}></span>

              <span className="flex-column centered">
                <span>Create</span>
              </span>
            </span>
          </button>
				</div>
				
				<div className={ `submenu ${selectedSubMenu === 'projects' ? 'visible' : ''}` }>
					<button className="submenu_close" onClick={ closeSubmenu }>Back</button>

					<h3 className="submenu_title">Dashboard</h3>

					<div className="projectsList">
            { experiments.map((experiment, rowIndex) => {
              return (
                <div className="projectItem" key={ experiment.previewId }>
                  <div className="iframe-aligner">
                    <iframe className={ `page-option-preview projects-view` }
                            height="100%"
                            id={ experiment.previewId }
                            loading={ [1, 2, ].includes(rowIndex) ? "eager" : "lazy" }
                            src={ `${process.env.REACT_APP_API_URL}/v1/templates/${experiment.previewId}` }
                            title={ `${experiment._id}` }
                            width="100%"
                    />
                  </div>

                  <h4 className="projectItem_title">{ experiment.title }</h4>

                  <div className="projectItem_actions">
                    <button className="button button--sm button--filled" onClick={ () => { navigate(`/experiments/${experiment._id}/edit`); toggleMenu(); } }>Edit</button>
                    <button className="button button--sm button--filled" onClick={ () => { navigate(`/experiments/${experiment._id}`); toggleMenu(); } }>View</button>
                  </div>
                </div>
              )
            })}

            { experiments.length > 0 && experiments.length % 5 === 0 &&
                <button className="button--square" id="load-more-projects-button" onClick={ () => setExperimentLimit(experimentLimit + 5) }>
                  Load More
                </button>
            }
					</div>

					<div className="submenu_actions">
            <button className="button button--filled button--sized" onClick={ goToCreate }>
              <span className="flex-row centered">
                <span className="flex-column centered">
                  <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.5 6.906h4.156a.75.75 0 0 1 0 1.5H8.5v4.157a.75.75 0 1 1-1.5 0V8.406H2.844a.75.75 0 0 1 0-1.5H7V2.75a.75.75 0 1 1 1.5 0v4.156Z" />
                  </svg>
                </span>

                <span style={{ margin: '0 2px', }}></span>

                <span className="flex-column centered">
                  <span>Create</span>
                </span>
              </span>
            </button>
					</div>
				</div>

				<div className={ `submenu ${selectedSubMenu === 'profile' ? 'visible' : ''}` }>
					<button className="submenu_close" onClick={ closeSubmenu }>Back</button>

					<h3 className="submenu_title">Profile</h3>

					<div className="formRow">
						<input type="text" onChange={ (e) => setUserPhoneNumber(e.target.value) } placeholder="Phone Number" value={ userPhoneNumber } />
						<label className="formRow_label">Phone Number</label>
					</div>

					<div className="formRow">
						<input type="text" onChange={ (e) => setUserUsername(e.target.value) } placeholder="Username" value={ userUsername } />
						<label className="formRow_label">Username</label>
					</div>

					{/* <div className="formRow">
						<input type="password" onChange={ (e) => setUserPassword(e.target.value) } placeholder="xxxxxxxx" value={ userPassword } />
						<label className="formRow_label">Password</label>
					</div> */}

					<div className="formRow">
						<input type="text" onChange={ (e) => setUserEmail(e.target.value) } placeholder="Add your email" value={ userEmail } />
						<label className="formRow_label">E-mail</label>
					</div>

					<div className="mt5">
						<button className="button button--sm" onClick={ utilities.user.signOut }>Sign Out</button>
					</div>

					<div className="submenu_actions">
						<button className="button button--filled button--sized" onClick={ updateUser }>
							<span>Save</span>
						</button>
					</div>
				</div>

				<div className={ `submenu ${selectedSubMenu === 'notifications' ? 'visible' : ''}` }>
					<button className="submenu_close" onClick={ closeSubmenu }>Back</button>

					<h3 className="submenu_title">Notifications</h3>

					<div className="formRow formRow--checkbox">
						<label className="formRow_label toggle-label">
							E-mail Notifications
							<input type="checkbox" checked={ userReceiveEmails } onChange={ () => setUserReceiveEmails(!userReceiveEmails) } />
							<span className="pretty-toggle"></span>
						</label>
					</div>

					<div className="formRow formRow--checkbox">
						<label className="formRow_label toggle-label">
							Phone Notifications
							<input type="checkbox" checked={ userReceiveTexts } onChange={ () => setUserReceiveTexts(!userReceiveTexts) } />
							<span className="pretty-toggle"></span>
						</label>
					</div>

					<p className="mt5">
						<button className="button button--sm" onClick={ () => { setUserReceiveEmails(false); setUserReceiveTexts(false); } }>Unsubscribe All</button>
					</p>

					<p className="sp2 mt5">If you have any questions please refer to our<br/><a href="/terms-of-use">Terms of Use</a> and <a href="/privacy-policy">Privacy Policy</a>.</p>
				</div>

				<div className={ `submenu ${selectedSubMenu === 'language' ? 'visible' : ''}` }>
					<button className="submenu_close" onClick={ closeSubmenu }>Back</button>

					<h3 className="submenu_title">Language</h3>

					<div className="formRow formRow--radio">
						<label className="formRow_label radio-label">
								English
								<input type="radio" name="lang" checked={ userLanguage === 'english' } onChange={ () => setUserLanguage('english') } />
								<span className="pretty-radio"></span>
						</label>
					</div>

					{/* <div className="formRow formRow--radio">
						<label className="formRow_label radio-label disabled">
							Français
              <span className="lock-icon">&#128274;</span>
							<input type="radio" name="lang" checked={ userLanguage === 'french' } onChange={ () => setUserLanguage('french') } />
							<span className="pretty-radio"></span>
						</label>
					</div>

					<div className="formRow formRow--radio">
						<label className="formRow_label radio-label disabled">
							Español
              <span className="lock-icon">&#128274;</span>
							<input type="radio" name="lang" checked={ userLanguage === 'spanish' } disabled onChange={ () => setUserLanguage('spanish') } />
							<span className="pretty-radio"></span>
						</label>
					</div> */}
				</div>
			</div>
		</span>
	);
}

export default Header;
