import { useEffect, useState, } from 'react';
import { useLocation, useNavigate, } from "react-router-dom";

import CreationFormNavigation from './CreationFormNavigation';
import CreationFormQuestions from './CreationFormQuestions';
import VideoBackground from './VideoBackground';

function CreateExperimentPage({ utilities, }) {
  const location = useLocation();
  const navigate = useNavigate();

  const initalState = location.state || { };
  const user = { _id: localStorage.getItem('userId'), username: localStorage.getItem('username'), };

  useEffect( utilities.user.requireAuthenticated, []);
  useEffect(() => { setTimeout(clearFocus, 250) }, []);

  const [activeStep, setActiveStep] = useState(initalState.activeStep || 0);
  const [selectedCategory, setCategory] = useState('');
  const [selectedDescription, setDescription] = useState('');
  const [selectedFont, setFont] = useState('');
  const [selectedStyle, setStyle] = useState('');
  const [selectedTitle, setTitle] = useState('');

  // must be lower for initialization purposes
  useEffect(() => { pulseVideo(); animateCurrentStep(activeStep); }, [activeStep, ]);

  function animateCurrentStep(stepNumber) {
    document.querySelectorAll(`.char.title-${stepNumber}`).forEach((char, i) => {
      setTimeout((char) => { char.classList.add('shown'); }, i * 15, char);
    });
  }

  function clearFocus() {
    document.querySelectorAll(`.step_title.title-${activeStep}`).forEach((elem) => elem.classList.remove('field-focused'));
    document.querySelectorAll('#video-background-container').forEach((elem) => elem.classList.remove('field-focused'));
  }

  function goToNext() {
    if (isNextButtonActive()) {
      clearFocus();
      // move to new step
      setActiveStep(Math.min(activeStep + 1, 7));
      // submit if at end
      if (activeStep === 6) submitResponses();
    }
  }

  function goToPrevious() {
    if (activeStep > 0) {
      document.querySelectorAll(`.step_title.title-${activeStep}`).forEach((elem) => elem.classList.remove('field-focused'));
      document.querySelectorAll('#video-background-container').forEach((elem) => elem.classList.remove('field-focused'));

      setActiveStep(activeStep - 1);
    }
  }

  function isNextButtonActive() {
    switch (activeStep) {
      case 0:
        return true;
      case 1:
        return !!selectedTitle;
      case 2:
        return !!selectedDescription;
      case 3:
        return !!selectedCategory;
      case 4:
        return !!selectedStyle;
      case 5:
        return true;
      case 6:
        return true;
      default:
        return true;
    }
  }

  function onFieldFocus() {
    document.querySelectorAll(`.step_title.title-${activeStep}:not([class*="field-focused"]`).forEach((elem) => elem.classList.add('field-focused'));
    document.querySelectorAll('#video-background-container:not([class*="field-focused"]').forEach((elem) => elem.classList.add('field-focused'));
  }

  function pulseVideo() {
    setTimeout(() => {
      document.querySelectorAll('#video-background-container:not([class*="pulsing"]').forEach((elem) => {
        elem.classList.add('pulsing');
        setTimeout(() => elem.classList.remove('pulsing'), 400);
      });
    }, 100);
  }

  async function showLoading(generatedIdLocation) {
    await new Promise(resolve => setTimeout(resolve, 750));

    const circleSvgElem = document.getElementById('circle-progress-svg');
    const percentageTextElem = document.getElementById('circle-progress-text');

    const initialValue = Number((window.getComputedStyle(circleSvgElem).strokeDashoffset || '0px').split('px')[0]);

    // state doesn't update while in here -- makes it difficult to check when something is actually done...
    for (let i = initialValue; i > 0; i--) {
      circleSvgElem.style.strokeDashoffset = `${i}px`;
      let percentageCompletion =  Math.round(100 - ((i / initialValue) * 100));
      percentageTextElem.innerHTML = `${percentageCompletion}%`;

      let timeoutLength = localStorage.getItem(generatedIdLocation) ? 1
        : percentageCompletion > 90 ? 50
          : percentageCompletion > 80 ? 40
            : percentageCompletion > 50 ? 35
              : percentageCompletion > 30 ? 30
                : 20;
      await new Promise(resolve => setTimeout(resolve, timeoutLength));
    }

    for (let i = 0; i <= initialValue + 10; i+=10) {
      circleSvgElem.style.strokeDashoffset = `${-i}px`;
      await new Promise(resolve => setTimeout(resolve, 0));
    }

    document.getElementById('generation-circle').classList.add('form-submitted');
    await new Promise(resolve => setTimeout(resolve, 250));
    document.getElementById('video-background-container').classList.add('form-submitted');
    await new Promise(resolve => setTimeout(resolve, 400));

    // stall until generation is completed (it should complete around 65% above)
    while(!localStorage.getItem(generatedIdLocation)) {
      await new Promise(resolve => setTimeout(resolve, 15));
    }

    navigate(`/experiments/${localStorage.getItem(generatedIdLocation)}/edit`)
  }

  function submitResponses() {
    const generatedIdLocation = Math.random().toString(36).substring(2) + Date.now().toString(36);
    showLoading(generatedIdLocation);

    fetch(`${process.env.REACT_APP_API_URL}/v1/experiments`, { body: JSON.stringify({ selectedCategory, selectedDescription, selectedFont, selectedStyle, selectedTitle, userId: user._id, }), headers: { 'Content-Type': 'application/json', }, method: 'POST', })
      .then((res) => res.json())
      .then((data) => localStorage.setItem(generatedIdLocation, data._id))
      .catch((err) => localStorage.setItem('message-for-user', 'Something went wrong. Please try again.'))
  }

  return (
    <section className="main">
      <CreationFormNavigation activeStep={ activeStep } />

      <CreationFormQuestions
        activeStep={ activeStep }
        goToNext={ goToNext }
        onFieldFocus={ onFieldFocus }
        setCategory={ setCategory }
        setDescription={ setDescription }
        setStyle={ setStyle }
        setStep={ setActiveStep }
        setTitle={ setTitle }
        submitResponses={ submitResponses }
        user={ user }
        utilities={ utilities }
      />

      <div className="formActions">
        <button className={ `button button--nav navPrev ${activeStep === 7 ? 'hidden' : ''}` } onClick={ goToPrevious }>
          <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.568 7.25h6.682a.75.75 0 0 1 0 1.5H5.568L8 11.183a.75.75 0 1 1-1.061 1.061L3.403 8.708a1 1 0 0 1 0-1.415L6.94 3.758a.75.75 0 0 1 1.06 1.06L5.569 7.252Z" />
          </svg>
        </button>

        <button className={ `button button--nav navNext ${activeStep === 7 ? 'hidden' : ''} ${isNextButtonActive() ? 'button--filled' : ''}` } onClick={ goToNext }>
          <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.432 8.75H3.75a.75.75 0 0 1 0-1.5h6.682L8 4.818a.75.75 0 1 1 1.06-1.06l3.536 3.535a1 1 0 0 1 0 1.414l-3.535 3.536A.75.75 0 0 1 8 11.183l2.432-2.433Z" />
          </svg>
        </button>
      </div>

      <VideoBackground selectedStyle={ selectedStyle } />
    </section>
  );
}

export default CreateExperimentPage;
